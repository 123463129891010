@import "../../node_modules/bulma/sass/utilities/initial-variables";

/* overwrite Bulma Variables */
// see https://bulma.io/documentation/customize/variables/
$text-strong: $black;

@import "../../node_modules/bulma/bulma";

tr:nth-child(2n) {
    background: #f5f5f5;
}

#calculator-1  {
    [contenteditable="true"] {
        position: relative;

        &::after {
        content: '';
        position: absolute;
        left: 80px;
        top: 18px;
        width: 17px;
        height: 17px;
        background-image: url('//cdn-x.omniconvert.com/public/uploads/2022-02/5d89b5af7e074bde1d92c90452e24c2e.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        }
     }

     .test-container {
         td {
            vertical-align: middle;
            }
     }
}